import { NavLink } from "react-router-dom";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { userRolesState } from "../auth/userRolesState";
import { userFeaturesState } from "../auth/userFeaturesState";

import { hasRole, hasFeature } from "../auth/util";

import {
  HomeIcon,
  ChartBarIcon,
  GlobeAltIcon,
  UserIcon,
  Cog8ToothIcon,
  InformationCircleIcon,
  ChevronDownIcon,
  FingerPrintIcon,
  IdentificationIcon,
  ArrowPathRoundedSquareIcon,
  NewspaperIcon,
  Bars3BottomRightIcon,
  BellIcon
} from "@heroicons/react/24/outline";
import InfoPopup from "../core/controls/InfoPopup";

function Navigation(props) {
  const userRoles = useRecoilValue(userRolesState);
  const userFeatures = useRecoilValue(userFeaturesState);

  return (
    <>   
      <div className='navigation'>
        <ul className='flex flex-col gap-1'>
          <li>
            <InfoPopup content="Dashboard" leftArrow={true} icon={
              <NavLink className='navbar-item' to='/'>
              <div>
                <img src="/icons/Dashboard.png"/>
              </div>
            </NavLink>
            }/>			
          </li>
          { hasFeature({ features: userFeatures }, "PricingAndInventory") ? (
            <>
              <li>
              <InfoPopup content="Pricing And Inventory" leftArrow={true} icon={           
                <NavLink className='navbar-item' to='/reports/pricing-and-inventory'>
                  <div>
                    <img src="/icons/PricingAndInventory.png"/>
                  </div>
                </NavLink>
                }/>	
              </li>
            </>
          ) : (
            <></>
          )}
          { hasFeature({ features: userFeatures }, "BuyOpportunity") ? (
            <>
              <li>
              <InfoPopup content="Buy Opportunity" leftArrow={true} icon={           
                <NavLink className='navbar-item' to='/reports/buy-opportunity'>
                  <div>
                    <img src="/icons/BuyOpportunity.png"/>
                  </div>
                </NavLink>
                }/>	
              </li>
            </>
          ) : (
            <></>
          )}
          { hasFeature({ features: userFeatures }, "ChannelManager") ? (
            <>
              <li>
                <InfoPopup content="Channel Manager" leftArrow={true} icon={
                  <NavLink className='navbar-item' to='/reports/cross-referenced-inventory'>
                  <div>
                    <img src="/icons/ChannelManager.png"/>
                  </div>
                </NavLink>
                }/>
              </li>
            </>
          ) : (
            <></>
          )}
          { hasFeature({ features: userFeatures }, "NewProductIntroduction") ? (
            <>
              <li>
                <InfoPopup content="New Product Introduction" leftArrow={true} icon={
                  <NavLink className='navbar-item' to="/reports/new-product-introduction">
                  <div>
                    <img src="/icons/NewProductIntroduction.png"/>
                  </div>
                </NavLink>
                }/>
              </li>
            </>
          ) : (
            <></>
          )}

          { hasFeature({ features: userFeatures }, "ComplianceInsights") ? (
            <>
              <li>
              <InfoPopup content="Compliance Insights" leftArrow={true} icon={           
                <NavLink className='navbar-item' to='/reports/compliance-insights'>
                  <div>
                    <img src="/icons/ComplianceInsights.png"/>
                  </div>
                </NavLink>
                }/>	
              </li>
            </>
          ) : (
            <></>
          )}
          
          { hasFeature({ features: userFeatures }, "CrossRefManager") ? (
            <>
              <li>
                <InfoPopup content="Cross Ref Manager" leftArrow={true} icon={
                  <NavLink className='navbar-item' to='/coming-soon' cursor-not-allowed="true">
                    <div className='flex flex-col '>
                      <img src="/icons/CrossReferenceManager.png"/>
                    </div>
                  </NavLink>
                }/>
              </li>
            </>
          ) : (
            <></>
          )}

          {hasRole({ roles: userRoles }, [ "Application-Admin", "Customer-Admin", "Customer-User" ]) ? (
            <>
              <li>
                <InfoPopup content="My Lists" leftArrow={true} icon={
                  <NavLink className='navbar-item' to='/partlists'>
                    <div>
                      <Bars3BottomRightIcon className='icon' />
                    </div>
                  </NavLink>
                }/>
              </li>              
              { hasFeature({ features: userFeatures }, "ChannelManager") ? (
                <>
                  <li>
                    <InfoPopup content="Alerts" leftArrow={true} icon={
                      <NavLink className='navbar-item' to='/alerts'>
                        <div>
                          <img src="/icons/Alerts.png"/>
                        </div>
                      </NavLink>
                    }/>
                  </li>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        
          <li className='navbar-folder hidden'>
            <input type='checkbox' className='navbar-folder-toggle peer' />
            <a className='navbar-item' href='#'>
              <div>
                <ChartBarIcon className='icon' />
                Reports
              </div>
              <ChevronDownIcon className='arrow' />
            </a>
            <div className='navbar-folder-target'>
              <ul>
                <li>
                  <NavLink className='navbar-item' to='/reports/part-search'>
                    Part&nbsp;Search
                  </NavLink>
                </li>
                <li>
                  <NavLink className='navbar-item' to='/reports/native'>
                    Native&nbsp;Reports
                  </NavLink>
                </li>
                <li>
                  <NavLink className='navbar-item' to='/reports/custom'>
                    Custom&nbsp;Reports
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          { hasRole({ roles: userRoles }, "Application-Admin") ? (
            <>
              <li>
              <InfoPopup content=' Customers' leftArrow={true} icon={
                <NavLink className='navbar-item' to='/customers'>
                  <div>
                    <GlobeAltIcon className='icon' />									
                  </div>
                </NavLink>
                }/>
              </li>
              <li>
                <InfoPopup content="Users" leftArrow={true} icon={
                  <NavLink className='navbar-item' to='/users'>
                    <div>
                        <img src="/icons/Users.png"/>
                    </div>
                  </NavLink>
                }/>
              </li>
              <li>
              <InfoPopup content='Report Chart Configs' leftArrow={true} icon={
                
                <NavLink className='navbar-item' to='/reports/configs'>
                  <div>
                    <img src="/icons/ReportConfigs.png"/>
                  </div>
                </NavLink>
                }/>
              </li>
            </>
          ) : (
            <></>
          )}
          
          <li className='hidden'>
            <NavLink className='navbar-item' to='/about'>
              <div>
                <InformationCircleIcon className='icon' />
                About
              </div>
            </NavLink>
          </li>
          <li>
            <InfoPopup content="About" leftArrow={true} icon={
              <NavLink className='navbar-item' to='/about'>
                <div>
                  <img src="/icons/Settings.png"/>
                </div>
              </NavLink>
            }>			  
            </InfoPopup>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
